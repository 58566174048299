<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <div class="row">
        <div class="col-md-4 col-xl-4">
            <app-card [hidHeader]="true" cardClass="bg-c-green order-card">
              <h6 class="text-white">Total Amount (Rs)</h6>
              <h2 class="text-right text-white"><i class="feather icon-tag float-left"></i><span>{{ total_amount }}</span></h2>
            </app-card>
          </div>
          <div class="col-md-4 col-xl-4">
            <app-card [hidHeader]="true" cardClass="bg-c-yellow order-card">
              <h6 class="text-white">Total Discount (Rs)</h6>
              <h2 class="text-right text-white"><i class="feather icon-tag float-left"></i><span>{{ total_discount }}</span></h2>
            </app-card>
          </div>
          <div class="col-md-4 col-xl-4">
            <app-card [hidHeader]="true" cardClass="bg-c-blue order-card">
              <h6 class="text-white">Total Final Amount (Rs)</h6>
              <h2 class="text-right text-white"><i class="feather icon-tag float-left"></i><span>{{ total_final_amount }}</span></h2>
            </app-card>
          </div>
    </div>
    <app-card>
      <perfect-scrollbar [style.max-height]="'500px'">
        <div class="table-responsive ng-tns-c94-73">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover"> 
          <thead>
          <tr>
            <th><span>Lab Name</span></th>
            <th><span>Customer Name</span></th>
            <th><span>Service Type</span></th>
            <th><span>JobDate</span></th>
            <th><span>Amount</span></th>
            <th><span>Discount</span></th>
            <th><span>Total</span></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getJobListArray">
 
              <td>{{ val.labss.Name }} <br>{{ val.labss.contactNo }} </td>
              <td>{{ val.user.fullName }} <br> {{ val.user.registerNo }} <br> {{ val.user.cardNo }} <br> {{ val.user.contactNo }}</td>
              <td>{{ val.serviceType }}</td>
              <td>{{ val.date }} <br> {{ val.time }}</td>
              <td>{{ val.amount }}</td>
              <td>{{ val.discount }}</td>
              <td>{{ val.totalPrice }}</td>
              
            </tr>
  
          </tbody>
        </table>
      </div>
      </perfect-scrollbar>
    </app-card>
  </div>
</div>
