<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
      <button class="btn btn-info" (click)="ViewList()">Refresh List</button>
        <div class="table-responsive ng-tns-c94-73">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            <th><span>Reg NO</span></th>
            <th><span>Center Name</span></th>
            <th><span>Full Name</span></th>
            <th><span>Contact No</span></th>
            <th><span>Patient Name</span></th>
            <th><span>Patient Tel</span></th>
            <th><span>Doctor Name</span></th>
            <th><span>Specialist</span></th>
            <th><span>Booking DATE</span></th>
            <th><span>Request Date Time</span></th>
            <th><span>status</span></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getCustomerListArray">
              <td>{{ val.user.registerNo }}</td>
              <td>{{ val.center.centername}} <br> {{ val.center.centernumber }}</td>
              <td>{{ val.user.fullName }}</td>
              <td>{{ val.user.contactNo }}</td>
              <td>{{ val.name }}</td>
              <td>{{ val.contactNumber }}</td>
              <td>{{ val.doctorName }}</td>
              <td>{{ val.specialist }}</td>
              <td>{{ val.bookingDate }}</td>
              <td>{{ val.requestDate }}</td>
              <td>

                <button type="button" class="btn btn-icon btn-success ng-tns-c85-29" (click)="Approve(val._id)">
                  <i _ngcontent-khb-c116="" class="feather icon-user-check"></i>
                </button>

                <button type="button" style="margin-left: 5px;" class="btn btn-icon btn-danger ng-tns-c85-29" (click)="delete(val._id)">
                  <i _ngcontent-khb-c116="" class="feather icon-trash"></i>
                </button>




              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div>
