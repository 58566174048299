import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.scss']
})
export class AddDoctorComponent implements OnInit {

  getName = "";
  getContactNumber = "";
  getDescription = "";
  getExperience = "";
  getNIC = "";
  getQualification = "";
  getLogo = "";
  getTime = "";
  getPrice = "";
  getDiscount = "";

  getSelectChanneling = "";
  getSelectSpecialization = "";

  getChannelingList: any = [];
  getSpecializationList: any = [];

  getLabListArray : any = [];
  temp;


  getFileType = "";
  labID = "";

  ImgSelectedFile: File = null;

  constructor(private toastr: ToastrService, private webApiService: WebapiService,) {
    this.labID = sessionStorage.getItem("channel"); 
    this.viewChanneling();
    this.viewSpecialization();
  }

  ngOnInit(): void {
  }
 
  Validation(){

    if(this.getName == "" || this.getContactNumber == ""){
      this.toastr.error('Please Fill Empty Fields', 'Error!');
    }else{
      // if(this.getFileType == "image/png" || this.getFileType == "image/jpeg" || this.getFileType == "image/jpg"){
        this.Save();
      // }else{
      //   this.toastr.error('Selected File Not Valid. please select Image.', 'Error!');
      // }
    }
}

Save(){

  const labData = {
    channeling: this.getSelectChanneling,
    specialization: this.getSelectSpecialization,
    name: this.getName,
    contact: this.getContactNumber,
    description: this.getDescription,
    experience: this.getExperience,
    nic: this.getNIC,
    qualification: this.getQualification,
    image: this.getLogo,
    addedBy: 'admin',
    time: this.getTime,
    price: this.getPrice,
    ourPrice: this.getDiscount,
  };

  this.webApiService.saveDoctor(labData).subscribe((res => {

      console.log(res);
      this.toastr.success("Insert Success", 'Success!');
      // let response = JSON.stringify(res);
      // let e_data = JSON.parse(response).Data;
      // let e_code = JSON.parse(response).Code;

      // if(e_code == "S100"){
      //   this.toastr.success(e_data, 'Info!');
      //   this.router.navigate(['/Login']);
      // }else if(e_code == "E100"){
      //   this.toastr.error(e_data, "Error!");
      // }else{
      //   this.toastr.error('Something went wrong Please try Again', 'Error!');
      // }

  }), (err) => {
    console.log(err);

      this.toastr.error('Something went wrong Please try Again', 'Error!');
  })





}

_handleReaderLoaded(readerEvt) {
  var binaryString = readerEvt.target.result;
  this.getLogo = btoa(binaryString);

}

 getBase64(event)  {
  this.ImgSelectedFile = event.target.files;
  this.getFileType = this.ImgSelectedFile[0].type;
  var reader = new FileReader();
  reader.onload = this._handleReaderLoaded.bind(this);
  reader.readAsBinaryString(this.ImgSelectedFile[0]);

}

viewChanneling(){


  this.webApiService.getChannel().subscribe((res => {

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        this.getChannelingList = res; 

        this.temp = res;

        var x = this.temp.find(x => x._id === this.labID);
      //  console.log(x); 
         
        this.getChannelingList = x; 
        //console.log(this.getLabListArray);
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })

}

viewSpecialization(){


    this.webApiService.getSpecialization().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getSpecializationList = res;
          //console.log(this.getLabListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })

}


}
