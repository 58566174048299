<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
        <div class="table-responsive ng-tns-c94-73">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            <th><span>Reg NO</span></th>
            <th><span>Member Card No</span></th>
            <th><span>Full Name</span></th>
            <th><span>City</span></th>
            <th><span>Tel</span></th>
            <th><span>Ref Code</span></th>
            <th><span>Active Month</span></th>
            <th><span>Reg DATE</span></th> 
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getCustomerListArray">
              <td>{{ val.registerNo }}</td>
              <td>{{ val.cardNo }} <button class="btn btn-info" (click)="updateMemberID(val._id)">Edit</button></td>
              <td>{{ val.fullName }}</td>
              <td>{{ val.city }}</td>
              <td>{{ val.contactNo }}</td>
              <td>{{ val.refCode }}</td>
              <td>{{ val.activeMonth }}</td>
              <td>{{ val.registerDate }}</td> 
            </tr>
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div> 
