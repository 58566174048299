<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Fill Details">
      <div class="row">
        <div class="col-md-12">
          <form action="javascript:">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Select Channeling Center</label>
              <div class="col-sm-9">
                <select
                    class="custom-select"
                    required
                    name="getSelectChanneling" 
                    [(ngModel)]="getSelectChanneling"> 
                  <option value="">Select</option>
                  <!-- <option *ngFor="let val of getChannelingList" value="{{ val._id }}">{{ val.name }}</option> -->
                  <option value="{{ getChannelingList._id }}" >{{ getChannelingList.name }}</option>  
                </select>
              </div> 
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Select Specialization</label>
              <div class="col-sm-9">
                <select
                    class="custom-select"
                    required
                    name="getSelectSpecialization"
                    [(ngModel)]="getSelectSpecialization">
                  <option value="">Select</option>
                  <option *ngFor="let val of getSpecializationList" value="{{ val._id }}">{{ val.name }}  / {{ val.description }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Doctor Name<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getName"
                  [(ngModel)]="getName">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Contact Numbers<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getContactNumber"
                  [(ngModel)]="getContactNumber">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">NIC<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getNIC"
                  [(ngModel)]="getNIC">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Experience<span>*</span></label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  name="getExperience"
                  [(ngModel)]="getExperience">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Qualification<span>*</span></label>
              <div class="col-sm-9">
                <textarea
                  name="getQualification"
                  [(ngModel)]="getQualification"
                  class="form-control"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">time<span>*</span></label>
              <div class="col-sm-9">
                <input
                  name="getTime"
                  [(ngModel)]="getTime"
                  class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Normal Price<span>*</span></label>
              <div class="col-sm-9">
                <input
                  name="getPrice"
                  [(ngModel)]="getPrice"
                  class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Discount Price<span>*</span></label>
              <div class="col-sm-9">
                <input
                  name="getDiscount"
                  [(ngModel)]="getDiscount"
                  class="form-control"/>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Description<span>*</span></label>
              <div class="col-sm-9">
                <textarea
                  name="getDescription"
                  [(ngModel)]="getDescription"
                  class="form-control"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-3 col-form-label">Profile Image<span>*</span></label>
              <div class="col-sm-9">
                <input
                  (change)="getBase64($event)"
                  style="margin-top: 10px;"
                  type="file"
                  class="form-control"
                  accept=".png,.jpg,.jpeg"
                  >
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="button" class="btn btn-success col-md-12" (click)="Validation()">Save</button>
            </div>
          </div>

        </div>
      </div>
    </app-card>
  </div>
</div>
