import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';
import { Subject } from 'rxjs';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-testing',
  templateUrl: './view-testing.component.html',
  styleUrls: ['./view-testing.component.scss']
})
export class ViewTestingComponent implements OnInit {

  getTestingListArray;

  labID = "";

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(private toastr: ToastrService,private webApiService: WebapiService,) {
    this.labID = sessionStorage.getItem("lab");
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  ngOnInit(): void {

    this.ViewList();
  }

  ViewList(){


    this.webApiService.getTestingData().subscribe((res => {

        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getTestingListArray = res;
          this.dtTrigger.next();
          console.log(this.getTestingListArray);
        }

    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })





  }

  delete(id){


    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to Delete this Testing?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#46DCBE',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.isConfirmed) {


        var data = {
          "id" : id
        }

        this.webApiService.deleteTesting(data).subscribe((res => {

          console.log(res);
          this.toastr.success("Success", res.toString());
          this.ViewList();

          // if(res == ""){
          //   this.toastr.error("Empty Data", "NO Data Found");
          // }else{
          //   this.getLabListArray = res;
          //   //console.log(this.getLabListArray);
          // }

      }), (err) => {
        console.log(err);
          this.toastr.error(err.error.Error, 'Error!');
      })


      }
    })

  }

}
