<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">

  <!-- <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-blue order-card">
      <h6 class="text-white">Registered Users</h6>
      <h2 class="text-right text-white"><i class="feather icon-shopping-cart float-left"></i><span>{{ registered }}</span></h2>
      
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-green order-card">
      <h6 class="text-white">Pending Users</h6>
      <h2 class="text-right text-white"><i class="feather icon-tag float-left"></i><span>{{ pendingUser }}</span></h2>
      
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-yellow order-card">
      <h6 class="text-white">Job Request</h6>
      <h2 class="text-right text-white"><i class="feather icon-award float-left"></i><span>{{ jobRequest }}</span></h2>
    
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-red order-card">
      <h6 class="text-white">Approve Count</h6>
      <h2 class="text-right text-white"><i class="feather icon-repeat float-left"></i><span>{{ approveCount }}</span></h2>
    
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-green order-card">
      <h6 class="text-white">Channeling Request</h6>
      <h2 class="text-right text-white"><i class="feather icon-repeat float-left"></i><span>{{ channelingRequestCount }}</span></h2>
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-yellow order-card">
      <h6 class="text-white">Premium Account Request</h6>
      <h2 class="text-right text-white"><i class="feather icon-repeat float-left"></i><span>{{ premiumRequestCount }}</span></h2>
    </app-card>
  </div> -->
</div>
 
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Pending Bloost Test Request">
      <button class="btn btn-info" (click)="ViewRequestedJobList()">Refresh List</button>
      <perfect-scrollbar>
        <div class="table-responsive ng-tns-c94-73">
        <table class="table table-hover m-b-0">
          <thead>
          <tr>
            <th><span>Service Type</span></th>
            <th><span>Name</span></th>
            <th><span>JobType</span></th>
            <th><span>bookingDate</span></th>
            <th><span>Price</span></th>
            <th><span>Request Date</span></th>
            <th><span>Action</span></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getRequestedJob">
              <td *ngIf="val.labID == labID"> 
                <h5 *ngIf="val.serviceType == 'Home Visit' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{ val.serviceType }}</span></h5>
                <h5 *ngIf="val.serviceType == 'Lab Visit' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-secondary ng-tns-c85-32">{{ val.serviceType }}</span></h5>
              </td>
              <td *ngIf="val.labID == labID">{{ val.memberName }}</td>
              <td *ngIf="val.labID == labID"> 
                <h5 *ngIf="val.JobType == 'Package' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-warning ng-tns-c85-32">{{ val.JobType }}</span></h5>
                <h5 *ngIf="val.JobType == 'Custom' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-info ng-tns-c85-32">{{ val.JobType }}</span></h5>
                <h5 *ngIf="val.JobType == 'Prescription' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-success ng-tns-c85-32">{{ val.JobType }}</span></h5>
              </td>
              <td *ngIf="val.labID == labID">{{ val.bookingDate }}</td>
              <td *ngIf="val.labID == labID">
                <h4> <span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-primary ng-tns-c85-32">{{ val.totalAmount }}</span></h4>
              </td>
              <td *ngIf="val.labID == labID">{{ val.requestDate }}</td>
              <td *ngIf="val.labID == labID">
                  <button type="button" class="btn btn-icon btn-success ng-tns-c85-29" *ngIf="val.JobType == 'Package' ">
                    <i _ngcontent-khb-c116="" [routerLink]="['/CreateJob-Package/',val._id]" class="feather icon-arrow-right"></i>
                  </button>

                  <button type="button" class="btn btn-icon btn-success ng-tns-c85-29" *ngIf="val.JobType == 'Custom' ">
                    <i _ngcontent-khb-c116="" [routerLink]="['/CreateJob-Custom/',val._id]" class="feather icon-arrow-right"></i>
                  </button>

                  <button type="button" class="btn btn-icon btn-success ng-tns-c85-29" *ngIf="val.JobType == 'Prescription' ">
                    <i _ngcontent-khb-c116="" [routerLink]="['/CreateJob-Prescription/',val._id]" class="feather icon-arrow-right"></i>
                  </button>
              </td>
            </tr>

          </tbody>
        </table></div>
      </perfect-scrollbar>

    </app-card>
  </div>
</div>

