<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Fill Details">
      <div class="row">
        <div class="col-md-12">
          <form action="javascript:">
              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Select Lab</label>
                <div class="col-sm-9">
                  <select
                      class="custom-select"
                      required
                      name="getSelectLab"
                      (change)="getLab()"
                      [(ngModel)]="getSelectLab">
                    <option value="">Select</option>
                    <option value="{{ getLabListArray._id }}" >{{ getLabListArray.name }}</option> 
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Package Name</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    name="getPackageName"
                    [(ngModel)]="getPackageName">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Select Testing</label>
                <div class="col-sm-9">
                  <select
                      class="custom-select"
                      required
                      name="getSelecctTesting"
                      (change)="getTesting()"
                      [(ngModel)]="getSelecctTesting">
                    <option value="">Select</option>
                    <option *ngFor="let val of getTestingListArray" value="{{ val._id }}">{{ val.name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">ID</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    name="getTestName"
                    [(ngModel)]="getTestName">
                </div>
              </div>
              <div class="form-group row">
                <label for="inputEmail3" class="col-sm-3 col-form-label">Description</label>
                <div class="col-sm-9">
                  <textarea
                    name="getDescription"
                    [(ngModel)]="getDescription"
                    class="form-control"></textarea>
                </div>
              </div>

            <div class="form-group row">
              <div class="col-sm-12" style="text-align: right;">
                <button type="reset" class="btn btn-danger col-md-6">Clear</button>
                <button type="button" class="btn btn-info col-md-6" (click)="AddToTable()">Add</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12">
          <app-card cardTitle="Blood Listing List">
            <perfect-scrollbar [style.max-height]="'362px'">
              <div class="table-responsive ng-tns-c94-73">
                <table class="table table-hover m-b-0">
                  <thead>
                  <tr>
                    <th><span>ID</span></th>
                    <th><span>Test Name</span></th>
                    <th><span>Description</span></th>
                    <th><span>Action</span></th>

                  </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let val of getTestingList; let i = index;">
                      <td>{{ val.testID }}</td>
                      <td>{{ val.testName }}</td>
                      <td>{{ val.description }}</td>
                      <td>
                          <button type="button" class="btn btn-icon btn-danger ng-tns-c85-29" (click)="deleteRow(i)">
                            <i _ngcontent-khb-c116="" class="feather icon-trash"></i>
                          </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </perfect-scrollbar>
          </app-card>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-3 col-form-label">Note</label>
            <div class="col-sm-9">
              <textarea
                name="getNote"
                [(ngModel)]="getNote"
                class="form-control"></textarea>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-3 col-form-label">Lab Price</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="getLabPrice"
                [(ngModel)]="getLabPrice">
            </div>
          </div>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-3 col-form-label">Our Price</label>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                name="getPrice"
                [(ngModel)]="getPrice">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <button type="button" class="btn btn-success col-md-12" (click)="Valication()">Save</button>
            </div>
          </div>

        </div>


      </div>



    </app-card>
  </div>
</div>
