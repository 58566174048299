<ngx-loading-bar color="#FFFF00" height="5px"></ngx-loading-bar>
<div class="row">
  <div class="col-sm-12">
    <app-card>
        <div class="table-responsive ng-tns-c94-73">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            <th><span>Doctor Name</span></th>
            <th><span>Specialization</span></th>
            <th><span>Availability</span></th>
            <th><span>Time</span></th>
            <th><span>Price</span></th>
            <th><span>Our Price</span></th>
            <th><span>Action</span></th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let val of getDoctorListArray">
              <td>{{ val.name }}</td>
              <td>{{ val.specialization.name }} <br> {{val.specialization.description}}</td>
              <td>
                <h5 *ngIf="val.availability == 'false' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-danger ng-tns-c85-32">Not Yet</span></h5>
                <h5 *ngIf="val.availability == 'true' "><span _ngcontent-lxe-c118="" class="badge m-r-5 badge-pill badge-info ng-tns-c85-32">Available</span></h5>
                <button type="button" class="btn btn-icon btn-success ng-tns-c85-29">
                  <i _ngcontent-khb-c116="" (click)="updateAvailability(val._id,val.availability)" class="feather icon-edit"></i>
                </button>
              </td>
              <td>{{ val.time }}</td>
              <td>{{ val.price }}</td>
              <td>{{ val.ourPrice }}</td>
              <td>
                <button type="button" class="btn btn-icon btn-dark ng-tns-c85-29">
                  <i _ngcontent-khb-c116="" [routerLink]="['/EditDoctor/',val._id]" class="feather icon-edit"></i>
                </button>
                <!-- <button type="button" class="btn btn-icon btn-danger ng-tns-c85-29" (click)="deleteLab(val._id)">
                  <i _ngcontent-khb-c116="" class="feather icon-trash"></i>
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
        </div>
    </app-card>
  </div>
</div>
