import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChannelingAPIService } from 'src/app/ClassFile/channel/channeling-api.service';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-edit-channeling',
  templateUrl: './edit-channeling.component.html',
  styleUrls: ['./edit-channeling.component.scss']
})
export class EditChannelingComponent implements OnInit {
  getID = "";
  getLabName = "";
  getContactNumber = "";
  getDescription = "";
  getCity = "";
  getLat = "";
  getLon = "";
  getLogo = "";
  getHours = "";

  getFileType = "";
  getOldLogo ="";
  getStatus = "Active";
 
  getCityListArray;

  ImgSelectedFile: File = null;

  constructor(private toastr: ToastrService,private webApiService: ChannelingAPIService,private route: ActivatedRoute, private webApiService2: WebapiService) {
    this.getID = this.route.snapshot.params.id;
    this.getChannel();
    this.ViewList();
  }

  getChannel(){

    let userData = {
      id: this.getID,
    }

    this.webApiService.viewChannelByID(userData).subscribe((res => {
      //console.log(res);

      if(res == ""){
        this.toastr.error("Empty Data", "NO Data Found");
      }else{
        let body = JSON.parse(JSON.stringify(res));
      //  console.log(body);

        this.getLabName = body.name;
        this.getContactNumber = body.contact;
        this.getDescription = body.description;
        this.getCity = body.city;
        this.getLat = body.lat;
        this.getLon = body.lng;
        this.getOldLogo = body.logo;
        this.getHours = body.openhours;
      }

  }), (err) => {
      this.toastr.error(err.error.Error, 'Error!');
  })




  }

 

  ngOnInit(): void {

  }

  Validation(){

        if(this.getLabName == "" || this.getContactNumber == "" || this.getDescription == "" || this.getCity == "" || this.getLat == "" || this.getLon == ""){
          this.toastr.error('Please Fill Empty Fields', 'Error!');
        }else{
            this.Save();
        }
  }

  Save(){

    const labData = {
      id: this.getID,
      name: this.getLabName,
      contact: this.getContactNumber,
      description: this.getDescription,
      openhours: this.getHours,
      city: this.getCity,
      lat: this.getLat,
      lng: this.getLon,
      logo: this.getLogo,
      oldImg: this.getOldLogo,
      addedBy: 'admin',
      status: this.getStatus
  };

    this.webApiService.updateChannel(labData).subscribe((res => {

        console.log(res);
        this.toastr.success(res + "", 'Updated!');
        // let response = JSON.stringify(res);
        // let e_data = JSON.parse(response).Data;
        // let e_code = JSON.parse(response).Code;

        // if(e_code == "S100"){
        //   this.toastr.success(e_data, 'Info!');
        //   this.router.navigate(['/Login']);
        // }else if(e_code == "E100"){
        //   this.toastr.error(e_data, "Error!");
        // }else{
        //   this.toastr.error('Something went wrong Please try Again', 'Error!');
        // }

    }), (err) => {
      console.log(err);

        this.toastr.error('Something went wrong Please try Again', 'Error!');
    })





  }

    _handleReaderLoaded(readerEvt) {
      var binaryString = readerEvt.target.result;
      this.getLogo = btoa(binaryString);

    }

    getBase64(event)  {
      this.ImgSelectedFile = event.target.files;
      this.getFileType = this.ImgSelectedFile[0].type;
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.ImgSelectedFile[0]);

  }

  ViewList(){


    this.webApiService2.getCites().subscribe((res => {
  
        if(res == ""){
          this.toastr.error("Empty Data", "NO Data Found");
        }else{
          this.getCityListArray = res;
          //console.log(this.getLabListArray);
        }
  
    }), (err) => {
        this.toastr.error(err.error.Error, 'Error!');
    })
  
  
  
  }


}
