import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebapiService } from 'src/app/ClassFile/webapi.service';

@Component({
  selector: 'app-auth-signin',
  templateUrl: './auth-signin.component.html',
  styleUrls: ['./auth-signin.component.scss']
})
export class AuthSigninComponent implements OnInit {

  getEmail: String = "";
  getPassword: String = "";

  constructor(private toastr: ToastrService,private router: Router, private webApiService: WebapiService,) { }

  ngOnInit() {
  }


   
  checkPassword(){
    //console.log("run");
   // console.log(this.getEmail);

    // if(this.getEmail.trim() === "admin" && this.getPassword.trim() === "789"){
    //   sessionStorage.setItem('isLogin' , "true");
    //   this.router.navigate(['/Home']);
    // }else{
    //   this.toastr.error('Invalid Email or Password!', 'Error!');
    // }

    const labData = {
      userName: this.getEmail.trim(),
      password: this.getPassword.trim(),
    };

    this.webApiService.spLogin(labData).subscribe(
      (res) => {
       // console.log(res.labDetails);  

        var getLabDetails = res['labDetails'];
        var getChannelDetails = res['channeling'];
        console.log(getLabDetails._id);

        sessionStorage.setItem('isLogin' , "true");
        sessionStorage.setItem('lab' , getLabDetails._id);
        sessionStorage.setItem('channel' , getChannelDetails._id);
        
        this.toastr.success("Login Success!", "Success!");
       this.router.navigate(['/Home']);
      },
      (err) => {
        console.log(err);
        this.toastr.error("Something went wrong Please try Again", "Error!");
      }

    );



  }

}
